<template>
    <div class="my_treat_page">
        <div class="head">
            <div class="info" style="width: 65%;">
                <div class="text" style="font-size: 14px;">{{detail.customername}}</div>
                <div  class="text">
                    <span v-if="detail.nick_name">{{ detail.nick_name }}</span>
                    <span>{{detail.sex }}</span>
                    <span> {{detail.age}}岁</span>
                    <span>{{detail.customerphone}}</span>
                </div>
                
            </div>
            <div class="info" style="width: 50%;margin-left: 35px;">
                <!-- <div class="text" style="text-align: left;">诊所：{{ ClinicName }}</div>
                <div class="text">工号：{{ dispname }}</div> -->
            </div>
        </div>
        <div class="main_info">
            <div class="info_line">
                <span>身份证号</span>
                <p>{{detail.idcard || '--'}}</p>
            </div>
            <div class="info_line">
                <span>出生日期</span>
                <p>{{detail.brith || '--'}}</p>
            </div>
            <div class="info_line">
                <span>注册时间</span>
                <p>{{detail.registertime || '--'}}</p>
            </div>
            <div class="info_line">
                <span>余额</span>
                <p v-if="detail.balance" style="color: #FA6400;">￥{{detail.balance}}</p>
                <p v-else> --</p>
            </div>
            <div class="info_line desc">
                <span>过敏史</span>
                <p>{{detail.allergichistory || '--'}}</p>
            </div>
            <div class="info_line desc">
                <span>既往史</span>
                <p>{{detail.medicalhistory || '--'}}</p>
            </div>
        </div>
        <div class="btn_detail_info">
            <div class="btn_div" @click="reservation">
                <img src="@/assets/yuyue.png" alt="">
                <p>门诊预约</p>
            </div>
            <div class="btn_div" @click="medical">
                <i class="icon iconfont iconbingli"></i>
                <p>患者病历</p>
            </div>
            <div class="btn_div" @click="treat">
                <i class="icon iconfont iconyoujian"></i>
                <p>理疗记录</p>
            </div>
            <div class="btn_div" @click="check">
                <i class="icon iconfont iconshijian"></i>
                <p>检查历史</p>
            </div>
            <div class="btn_div" v-if="$route.query.customerid==0" @click="coupon">
                <img src="@/assets/coupon.png" alt="">
                <p>优惠券管理</p>
            </div>
            <div class="btn_div" v-if="$route.query.customerid==0" @click="card">
                <img src="@/assets/card.png" alt="">
                <p>卡包管理</p>
            </div>
        </div>




    </div>
</template>
<script>
import {wxtriagecustomerqry} from '@/api/medical'
import { mapGetters } from 'vuex'

export default{
    computed: {
            ...mapGetters([
                'username','dispname','clinicid'
            ]),
        },
    data(){
        return{
            detail:{
                customername:''
             },
            query:{
                
                qrypage:1,
                keyword:this.$route.query.keyword,
            }
        }
    },
    mounted(){
        this.$store.dispatch('hideOrShowNav', false)
        // this.detail = JSON.parse(localStorage.getItem('UserInfoDetail'))
        // console.log(JSON.parse(localStorage.getItem('UserInfoDetail')))
        // if(!this.detail){
              this.getuserinfo()
        // }
      
    },
    methods:{
        getuserinfo(){
            this.query.username = this.$route.query.userid,
            wxtriagecustomerqry(this.query).then(response =>{
                // 
                let arr = response.response_body.list
                if(!this.$route.query.customerid){
                    this.$route.query.customerid = 0;
                }
                   arr = response.response_body.list.filter(item => item.customerid == this.$route.query.customerid)
                if(arr.length||response.response_body.list.length==0){
                    this.detail = arr[0]
                }else{
                    this.query.qrypage += 1;
                    this.getuserinfo()
                }
            })
        },
        reservation() {
            this.$router.push({
                path: '/reservation',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid
                }
            })
        },
        medical() {
        console.log(this.detail.userid,'this.detail.userid')
        this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/medicalPage',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid
                }
            })
        },
        treat() {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/historyPage',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid,
                    name: this.detail.customername,
                    sex: this.detail.sex,
                    age: this.detail.age,
                    phone: this.detail.customerphone
                }
            })
        },
        check() {
            this.$store.dispatch('changeIsRefresh', '1')
            this.$router.push({
                path: '/checkPage',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid,
                    name: this.detail.customername,
                    sex: this.detail.sex,
                    age: this.detail.age,
                    phone: this.detail.customerphone
                }
            })
        },
        coupon() {
            this.$router.push({
                path: '/coupon',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid
                }
            })
        },
        card() {
            this.$router.push({
                path: '/card',
                query: {
                    userid: this.detail.userid,
                    customerid: this.detail.customerid,
                    // name: this.detail.customername,
                    // sex: this.detail.sex,
                    // age: this.detail.age,
                    // phone: this.detail.customerphone
                }
            })
        },
    }
 
}
</script>
<style lang="scss" scoped>
.my_treat_page{
    margin-bottom: 20px;
    min-height: 100vh;
    background-color: #F6F6F4;
    .head{
        background-color: #E7EBF7;
        border-radius: 10px;
        
        font-size: 12px;
        padding: 15px;
        display: flex;
        align-items: center;
        font-size: 12px;
        .text{
            margin-bottom: 10px;
            span{
                margin-right: 8px;
            }
        }
    }
    .main_info{
        margin-top: -20px;
        .info_line p{
        //超出多少行显示省略号
        -webkit-line-clamp: unset !important;
    
    }
    }
    .list{
        background-color: #fff;
        border-radius: 10px;
        margin-top: -15px;
        padding: 15px 12px;
        margin-bottom: 80px;
        .list_top{
            
            display: flex;

                flex-wrap: wrap;
            .user{
                width: 95px;
                height: 45px;
                padding: 5px;
                text-align: center;
                background-color: #F5F5F5;
                border-radius: 8px;
                margin-right: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 8px;
                
                .item{
                    width: 80px;
                    white-space: nowrap;      
                    overflow: hidden;         
                    text-overflow: ellipsis;
                }
                
            }
            .active{
                background: rgba(207,140,87,0.2);
                border-radius: 8px;
                border: 1px solid #CF8C57;
            }
        }
        .list_btn{
            width: 140px;
            height: 35px;
            line-height: 35px;
            border-radius: 17.5px;
            background-color: #CF8C57;
            font-size: 16px;
            color: #fff;
            text-align: center;
            margin-bottom: 10px;
        }
        .list_item{
            background-color: #F5F5F5;
            border-radius: 10px;
            padding: 10px;
            margin-bottom: 10px;

            .tream{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text{
                    font-weight: 600;
                    font-size: 16px;
                    color: #323333;
                    line-height: 22px;
                    text-align: left;
                }
            }
        }
    }
    .btn_detail_info{
        flex-wrap: wrap;
        padding: 5px;
        padding-left: 20px;
        :nth-child(3){
            border-right: unset;
        }
        .btn_div{
            margin: 5px 0;
            width: 32%;
            font-size: 12px;
            padding: 5px;
    
            // padding-left: 10px;
            // width: unset;
            // margin: 10px 0;
            img{
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
        }
    }
    .van-cell{
        padding:0 5px 5px;
        background-color: #F5F5F5;
       
        ::v-deep .van-icon {
            display: flex;
            align-items: center;
        }
    }

    
}
::v-deep .van-checkbox__label{
    width: 100% !important;
}
.my_treat_page .van-cell {
    ::v-deep .van-field{

        background-color: #fff;
    }
}
.van-dialog{

    ::v-deep .van-dialog__header{
        padding: 10px 0  25px !important;
    }
    ::v-deep .van-field{
        background-color: #fff;
        margin-bottom: 5px;
    }
}

</style>